.loginContainer {
  width: 100%;
  height: 100%;
}

.textField {
  width: 100%;
}

.margintTop10 {
  margin-top: 10px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop40 {
  margin-top: 40px;
}

.marginRight20 {
  margin-right: 20px;
}

.loginButton {
  width: 60%;
  align-self: center;
  margin-top: 30px;
}

.marginTop80 {
  margin-top: 80px;
}

.marginRight24 {
  margin-right: 24px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.link {
  padding-top: 20px;
  padding-left: 24px;
}

.select {
  width: 100%;
}

.progressContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
