.fullPageContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: scroll;
}

.searchButton {
  width: 85%;
  align-self: center;
}

.inputsContainer {
  margin-top: 20px;
}

.marginLeft20 {
  margin-left: 20px;
}

.marginLeft40 {
  margin-left: 40px;
}

.MuiDataGrid-root {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
}

.cellBorder {
  border: 1px solid rgba(224, 224, 224, 1);
}

.detailsTable {
  width: 70%;
  margin-top: 40px;
}

.detailsTableFull {
  width: 95%;
}

.userDetailsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.marginTop40 {
  margin-top: 40px;
}
