.helpContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.helpContainer img {
  border: 1px solid black;
  margin: 20px 20px 20px 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: calc(100% - 50px);
  object-fit: none;
}

.helpContainer p {
  margin-left: 20px;
  margin-right: 20px;
}

.helpNavigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
}

.helpNavigation strong {
  margin-bottom: 15px;
}

.helpNavigation .section {
  display: flex;
  flex-direction: column;
}

.helpNavigation a {
  margin: 5px 0px 5px 0px;
}
