.topModalContainer {
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.discountCoupons {
  background-color: white;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  pointer-events: all;
  display: flex;
  flex-direction: column;
}

.createDiscountModal {
  width: 50%;
  padding: 20px;
}

.couponsGrid {
  margin-bottom: 20px;
}

.height80vh {
  height: 80vh;
}

.disableAllButtonContainer {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0px 20px;
}

.clickable {
  cursor: pointer;
}

.skipEurofins {
  margin-left: 20px;
}

.skipEurofins span {
  font-weight: bold;
}
